$primary: #A2C617;
$secondary: #84a115;

// Import Bootstrap and its default variables
@import '../../node_modules/bootstrap/scss/bootstrap';

.btn-primary {
  color: white;
}
.navbar-light .navbar-brand {
  color: white;
}

.navbar-light .navbar-nav .nav-link {
  color: white;
}

.submit {
  background: $secondary
}

